import styled from 'styled-components';

export const Container = styled.div`
  margin: 22px auto;
  width: 1240px;
  @media (min-width: 768px){
    display: flex;
    width: 1240px;
    justify-content: center;
    flex-direction: ${(props) => ((props.layout === 'odd') ? 'row-reverse' : 'row')};
  }
  @media screen and (max-width: 1340px){
    width: 980px;
  }
  @media screen and (max-width: 980px){
    width: 80%;
  }
  @media screen and (max-width: 800px){
    width: 90%;
  }
  @media screen and (max-width: 667px){
    width: 85%;
  }
`;

export const ContentSection = styled.section`
  z-index: 1;
  h2 {
    margin-bottom: 24px;
  }
  @media (max-width: 1340px) {
    width: 40vw;
  }
  @media (min-width: 768px) {
      max-width:60vw;
      margin-top: 2vh;
  }
  @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
  }
  @media (max-width: 667px){
    h2 {
      font-size: 22px;
      line-height: 1.1;
      margin-bottom: 14px;
    }
  }
`;

export const ImageSection = styled.section`
  margin-left: ${(props) => ((props.layout === 'odd') ? 'auto' : '-100px')};
  margin-right: ${(props) => ((props.layout === 'odd') ? '-100px' : 'auto')};
  
  min-height: 250px;

  @media (max-width: 1340px) {
    width: 40vw !important;
  }
  @media (min-width: 768px) {
    width:70vw;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100% !important;
    margin-right: ${(props) => ((props.layout === 'even') ? '24px' : '0')};
    margin-left: 0;
    margin-bottom: 18px;
    min-height: unset;
  }
`;
