import PropTypes from 'prop-types';
import React from 'react';

import ResponsiveImage from '../ResponsiveImage';
import {
  Container,
  ContentSection,
  ImageSection,
} from './styledComponent';

const TextAndPicture = ({
  src, children, layout, parallax, isMission,
}) => (
  <Container layout={layout} isMission={isMission}>
    <ImageSection layout={layout}>
      <ResponsiveImage src={src} parallax={parallax} isMission={isMission} />
    </ImageSection>
    <ContentSection isMission={isMission}>
      {children}
    </ContentSection>
  </Container>
);

TextAndPicture.propTypes = {
  src: PropTypes.node,
  parallax: PropTypes.array,
  children: PropTypes.node,

  layout: PropTypes.string, // 'odd' or 'even'
};

TextAndPicture.defaultProps = {
  layout: 'odd',
};

export default TextAndPicture;
