import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  @media (max-width: 768px){
    width: 100%;
    margin-bottom: 64px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 64px;
  @media (max-width: 768px){
    margin-top: 24px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
`;
