import PropTypes from 'prop-types';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import Media from 'react-media';
import Container from './styledComponent';

const ResponsiveImage = ({
  src, alt, /* layout,  */parallax, hasParallax,
}) => (
  <Media query="(max-width: 768px)">
    {(matches) => ((matches || !hasParallax) ? (
      <Container>
        <img src={src} alt={alt} />
      </Container>

    ) : (
      <Parallax className="custom-class" y={parallax} tagOuter="figure">
        <Container>
          <img src={src} alt={alt} />
        </Container>
      </Parallax>
    ))}
  </Media>
);

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  parallax: PropTypes.array,
  hasParallax: PropTypes.bool,
};

ResponsiveImage.defaultProps = {
  parallax: [16, -32],
  hasParallax: true,
};

export default ResponsiveImage;
