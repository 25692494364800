import styled from 'styled-components';

const Container = styled.div`
  img
  {
    max-width:100%;
    max-height:100%;
  }
`;

export default Container;
