import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Layer from 'stoomlink-commons-ui/Layer';
import Title from 'stoomlink-commons-ui/Title';
import BodyText from 'stoomlink-commons-ui/BodyText';
import TextLoop from 'react-text-loop';

import Button from '../Button';
import {
  Container,
  ButtonsContainer,
  ButtonContainer,
} from './styledComponent';
import NewsletterForm from '../NewsletterForm';

const FloatingParagraph = ({
  title, titles, children, buttons,
}) => {
  const [newsletterVisible, setNewsletterVisible] = useState(false);
  const onNewsletterClose = () => {
    setNewsletterVisible(false);
  }

  return (
    <Container>
      {title && (
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          level={2}
        />
      )}
      {titles && (
        <Title level={2}>
          <TextLoop interval={2000}>
            {titles.map((item, i) => {
              return (
                <span
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                  key={i.toString()}
                />
              );
            })}
          </TextLoop>
        </Title>
      )}
      <section>
        <BodyText
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
        {buttons && (
          <ButtonsContainer>
            {buttons.map((button, i) => {
              const { title: buttonTitle, href, target, newsletterCTA } = button;
              return (
                <ButtonContainer key={`${i.toString() + buttonTitle}`}>
                  {
                    newsletterCTA ? (
                      <Button onClick={() => setNewsletterVisible(true)}>
                        {buttonTitle}
                      </Button>
                    ) : (
                      <Button href={href} target={target}>{buttonTitle}</Button>
                    )
                  }
                </ButtonContainer>

              );
            })}
          </ButtonsContainer>
        )}
      </section>
      <Layer>
        {
          newsletterVisible && <NewsletterForm onClose={onNewsletterClose} />
        }
      </Layer>
    </Container>
  )
};

FloatingParagraph.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,

  buttons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),

};


export default FloatingParagraph;
